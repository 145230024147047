// import videoOne from './image/video1.mp4'
// import videoTwo from './image/video2.mp4'
import videoThree from './image/Famnivision.mp4'
import logo1 from './image/rtt1.png'
import logo2 from './image/rtt2.png'
import logo3 from './image/rtt3.png'
import logo4 from './image/rtt4.png'
export const SliderData =[
// {
//    title:'love sees no gender',
//    common:"I know that",
//    path:'/homes',
//    lavel:'Contact me',
//    video: videoOne,
//    alt:'relax'
// },
// {
//    title:'to make your love last',
//    common:"I could help you",
//    path:'/homes',
//    lavel:'Contact me',
//    video: videoTwo,
//    alt:'relax'
// },

{
   title:"Employ the Alternative way to Reflect and Make a Positive Change with Sadia Afrin",
   common:"Master your mind with Solution-Focused Hypnotherapy",
   path:'/homes',
   lavel:'Contact me',
   video: videoThree,
   alt:'relax',
   logo1,
   logo2,
   logo3,
   logo4
},



]