import {BiBody} from 'react-icons/bi'
import {RiMentalHealthLine} from 'react-icons/ri'
import {MdNoFood} from 'react-icons/md'
import {MdFamilyRestroom} from 'react-icons/md'
import {BiDonateHeart} from 'react-icons/bi'
import {GiLovers} from 'react-icons/gi'
import {FaRegFrownOpen} from 'react-icons/fa'
import {GiBackPain} from 'react-icons/gi'
import {BiTired} from 'react-icons/bi'
import {FaRegSmileBeam} from 'react-icons/fa'
import {RiTrophyLine} from 'react-icons/ri'
import {RiEmotionLaughFill} from 'react-icons/ri'
import {GiBlackBook} from 'react-icons/gi'
import {SiRelianceindustrieslimited} from 'react-icons/si'
import {FaSkyatlas} from 'react-icons/fa'
import {GiPublicSpeaker} from 'react-icons/gi'


import BodyImg from './image/body.png'
import Healthimg from './image/health.jpg'
import EatingImg from './image/eatting.png'
import Familyimg from './image/family.jpg'
import Carrier from './image/rier.jpg'
import Romance from './image/rommance.jpg'
import Fear from './image/fear.jpg'
import Stress from './image/stress.jpg'
import Panic from './image/panic.jpg'
import Motivation from './image/motivation.jpg'
import Physical from './image/physical.jpg'
import Emotion from './image/emotional.jpg'
import Intellect from './image/intellectual.png'
import Spiritual from './image/spirituall.jpeg'
import Environment from './image/environment.png'
import Social from './image/Social.jpg' 
import Occupation from './image/occupaion.jpeg'
export default [
    {   
        id:1,
        imageName : BodyImg,
        name:"BODY IMAGE",
        details:`What do you see when you look in the mirror? \n Do you see your body in a positive or negative way? Are you happy or sad with the way you look? Ugh, my hair looks horrible! I think I need to lose alittle weight. My figure looks fabulous! These are all comments that we, as individuals, have said to ourselves at one point or another, and they all relate to body image.`,
        tag:"weight management",
        icon:<BiBody/>
    },
    {
        id:2,
        imageName : Healthimg,
        name:"HEALTH ISSUES",
        details:"Gastroesophageal reflux disease GERD Diabetes risk, metabolic syndrome, and prediabetes Type 2 diabetes Asthma reactive airway disease Cardiovascular disease* and cardiovascular disease mortality Hypertension Polycystic ovary syndrome PCOS Urinary stress incontinence",
        tag:"weight management",
        icon:<RiMentalHealthLine/>
    },
    {
        id:3,
        imageName : EatingImg,
        name:"EATING DISORDERS",
        details:"Common eating disorders include anorexia nervosa, bulimia nervosa, and binge-eating disorder. Anorexia nervosa is a condition where people avoid food, severely restrict food or eat very small quantities of only certain foods. Eating disorders are serious conditions that cause both physical and emotional damage. All eating disorders can lead to irreversible and even life-threatening health problems, such as heart disease, bone loss, stunted growth, infertility, and kidney damage. Many people worry about their weight, what they eat, and how they look.",
        tag:"weight management",
        icon: <MdNoFood/>
    },
    {
        id:4,
        imageName : Familyimg,
        name:"FAMILY",
        details:"  The state of being connected by blood, marriage or adoption. Maintaining a strong relationship requires  constant care and communication, and certain traits have been shown to be especially important for  fostering healthy relationships.",
        tag:"relationships",
        icon: <MdFamilyRestroom/>
    },
    {
        id:5,
        imageName : Carrier,
        name:"CAREER",
        details:" Positive relationships in the workplace are an integral part of career success. When building relationships in the workplace, you will notice an increase in productivity and overall job satisfaction. You can build lasting, professional relationships by taking certain steps toward understanding your emotional intelligence and  getting to know your coworkers.",
        tag:"relationships",
        icon: <BiDonateHeart/>
    },
    {
        id:6,
        imageName : Romance,
        name:"ROMANCE",
        details:"Love is one of the most profound emotions known to human beings. There are many kinds of love, but  many people seek its expression in a romantic relationship with a compatible partner. For these individuals romantic relationships comprise one of the most meaningful aspects of life, and are a source of deep fulfillment. While need for human connection appears to be innate, the ability to form healthy,  loving relationships is learned. ",
        tag:"relationships",
        icon: <GiLovers/>
    },
    {
        id:7,
        imageName : Stress,
        name:"STRESS",
        details:" Stress is a biological reaction that all of us can experience at any age. National Institutes of Mental Health (NIMH) define it as “how the brain and body respond to any demand.” Stress can be routine, brought on by sudden negative change or Ttauma experienced during an event such as an accident, war or a natural disaster. According to Harvard Health, as we get older, stress can come “as a result of managing br chronic illness, losing a spouse, being a caregiver, or adjusting to changes due to finances, retirement, or separation from friends and family.” Some people are able to cope with stress and recover better than others, but long-term stress can be difficult to manage and it could be harmful to your health.",
        tag:"self-esteem",
        icon: <BiTired/>
    },
    {
        id:8,
        imageName : Motivation,
        name:"MOTIVATION",
        details:"  Motivation is our drive to do things. It’s the element that keeps us moving towards our goals. Motivation is essential and can go up and down depending on our self-control and other external circumstances that affect our action. These uncontrollable factors further define the level of motivation a person has in terms of achievement.  You do the action and then the motivation comes not the other way around.",
        tag:"self-esteem",
        icon: <FaRegSmileBeam/>
    },
    {
        id:9,
        imageName : Physical,
        name:"PHYSICAL WELLNESS ",
        details:" refers to the body’s ability to function well and participate in a variety of activities without becoming too fatigued.These three elements form the core of physical wellness:  Exercise  Nutrition  Sleep Emotional Wellness is the ability to be aware of and manage your emotions. It also means you have a realistic view of yourself and your circumstances. Here are three tips for maintaining your emotional wellness:  Practice optimism  Practice mindfulness  Practice reframing",
        tag:"Wellness",
        icon: <RiTrophyLine/>
    },
    {
        id:10,
        imageName : Intellect,
        name:" INTELLECTUAL WELLNESS ",
        details:"is pursuing the cultivation of your mental and cognitive resources. In other words, being intellectually well means feeding your mind and sense of creativity. Here are three things you can do to support your intellectual health: Read Explore Create",
        tag:"Wellness",
        icon: <RiEmotionLaughFill/>
    },
    {
        id:11,
        imageName :Spiritual ,
        name:"  SPIRITUAL WELLNESS ",
        details:" concerns your ability to find meaning in life and your personal values.  Many people conflate spirituality with religion. Any activity that promotes alignment and brings your purpose into clearer focus will help develop your spiritual wellness. Here are some common ways to address spiritual wellness:",
        tag:"Wellness",
        icon: <GiBlackBook/>
    },
    {
        id:12,
        imageName : Social,
        name:" SOCIAL WELLNESS  ",
        details:"  is the ability to do these three things within a community:Communicate: A strong social community shares information, thoughts and feelings to build support for all its members.  	Contribute: Social systems offer the chance to contribute to  a community, and the ability to recognize the contributions of other members.  	Connect: The most important element of social wellness is the ability to forge  strong connections with others based on shared experiences and goals.",
        tag:"Wellness",
        icon: <FaSkyatlas/>
    },
]