import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
const AllPrograms = () => {
  return (
    <section className=" section contact-section pt-2">
    <Tabs
      defaultActiveKey="profile"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab className='tab-title' eventKey="Body-Image" title="Body Image">
        skdbuadlbaviubjk vabljbudslvl dvyoasidbcla ayd voalibjhsb syd gos bvlvg s dygsygldgfuysgdf  sdygfscusyg 
      </Tab>
      <Tab eventKey="Health-Issues" title="Health Issues">
        skdbuadlbaviubjk vabljbudslvl dvyoasidbcla ayd voalibjhsb syd gos bvlvg s dygsygldgfuysgdf  sdygfscusyg 
      </Tab>
    
      <Tab eventKey="Eating-Disorder" title="Eating Disorder" >
        skdbuadlbaviubjk vabljbudslvl dvyoasidbcla ayd voalibjhsb syd gos bvlvg s dygsygldgfuysgdf  sdygfscusyg 
      </Tab>
      <Tab eventKey="Family" title="Family" >
        skdbuadlbaviubjk vabljbudslvl dvyoasidbcla ayd voalibjhsb syd gos bvlvg s dygsygldgfuysgdf  sdygfscusyg 
      </Tab>
      <Tab eventKey="Career" title="Career" >
        skdbuadlbaviubjk vabljbudslvl dvyoasidbcla ayd voalibjhsb syd gos bvlvg s dygsygldgfuysgdf  sdygfscusyg 
      </Tab>
      <Tab eventKey="Romance" title="Romance" >
        skdbuadlbaviubjk vabljbudslvl dvyoasidbcla ayd voalibjhsb syd gos bvlvg s dygsygldgfuysgdf  sdygfscusyg 
      </Tab>
      <Tab eventKey="Fears-and-Phobias" title="Fears and Phobias" >
        skdbuadlbaviubjk vabljbudslvl dvyoasidbcla ayd voalibjhsb syd gos bvlvg s dygsygldgfuysgdf  sdygfscusyg 
      </Tab>
      <Tab eventKey="Panic-Attacks" title="Panic Attacks" >
        skdbuadlbaviubjk vabljbudslvl dvyoasidbcla ayd voalibjhsb syd gos bvlvg s dygsygldgfuysgdf  sdygfscusyg 
      </Tab>
      <Tab eventKey="Stress" title="Stress" >
        skdbuadlbaviubjk vabljbudslvl dvyoasidbcla ayd voalibjhsb syd gos bvlvg s dygsygldgfuysgdf  sdygfscusyg 
      </Tab>
      <Tab eventKey="Motivation" title="Motivation" >
        skdbuadlbaviubjk vabljbudslvl dvyoasidbcla ayd voalibjhsb syd gos bvlvg s dygsygldgfuysgdf  sdygfscusyg 
      </Tab>
    </Tabs>
  </section>
  )
}

export default AllPrograms
