import React, { useEffect, useState } from 'react';
import {BiGridSmall} from 'react-icons/bi'
import {FaWeight} from 'react-icons/fa'
import {MdSelfImprovement} from 'react-icons/md'
import {MdFamilyRestroom} from 'react-icons/md'
import {GiUbisoftSun} from 'react-icons/gi'
// import Healthimg from './image/health.jpg'
// import EatingImg from './image/eatting.png'
// import Familyimg from './image/family.jpg'
// import Carrier from './image/rier.jpg'
// import Romance from './image/rommance.jpg'
// import Fear from './image/fear.jpg'
// import Stress from './image/stress.jpg'
// import Panic from './image/panic.jpg'
// import Motivation from './image/motivation.jpg'
// import Physical from './image/physical.jpg'
// import Emotion from './image/emotional.jpg'
// import Intellect from './image/intellectual.png'
// import Spiritual from './image/spirituall.jpeg'
// import Environment from './image/environment.png'
// import Social from './image/Social.jpg' 
// import Occupation from './image/occupaion.jpeg'
import data from './data.js'
import {motion , AnimatePresence} from 'framer-motion'
import { Modal } from 'react-bootstrap';


const WelcomeNote = () => {
  const [tag, setTag] = useState('all services');
	const [filteredImages, setFilteredImages] = useState([]);
  const [show, setShow] = useState(false);
  const [progData , setData] = useState()
  const onClick = (e) =>{
    setShow(true)
    console.log(e.target)
    setData(e.target.value)
  
  } 

	useEffect(
		() => {
			tag === 'all services' ? setFilteredImages(data) : setFilteredImages(data.filter(items => items.tag === tag));
		},
		[tag]
	);

	return (
    <>
    <section className="mt-4">
      	
			<div className="tags">
        <h2 className='service-text-h'>
          <strong>SERVICES</strong> AT A GLANCE
          </h2>
         
          <BiGridSmall className='tag-icon'/> <TagButton name="all services" tagActive={tag === 'all services' ? true : false} handleSetTag={setTag} />
          <FaWeight className='tag-icon'/> <TagButton name="weight management" tagActive={tag === 'weight management' ? true : false} handleSetTag={setTag} /> 
          <MdSelfImprovement className='tag-icon'/><TagButton name="self-esteem" tagActive={tag === 'self-esteem' ? true : false} handleSetTag={setTag} /> 
          <MdFamilyRestroom className='tag-icon'/><TagButton name="relationships" tagActive={tag === 'relationships' ? true : false} handleSetTag={setTag} /> 
          <GiUbisoftSun className='tag-icon'/><TagButton name="Wellness" tagActive={tag === 'Wellness' ? true : false} handleSetTag={setTag} />
			</div>
			{/* <SRLWrapper options={options}> */}
      <AnimatePresence>
				<div className="prog-container">
          
					{filteredImages.map(items => (
          <>
						<motion.div key={items.id}
             className="prog-image-card"
            layout
            initial={{opacity:0}}
            animate={{opacity:1}}
            exit={{opacity:0}}
            transition={{duration:0.5}}
            > 

              {/* v3 */}
              {/* <figure class="snip0015">
	<img src={items.imageName} alt="sample38"/>
	<figcaption>
		<h2>{items.name}</h2>
		<p>{items.details.substring(0,50)}</p>
		<a href="#"></a>
	</figcaption>			
</figure> */}


              {/* v2 */}
							{/* <a onClick={onClick}  >
                <div className='post'>
                  <img className="prog-image" src={items.imageName} alt="" />
                <div className='post-s'>
                  <h2>{items.name}</h2>
                  <p>{items.details.substring(0, 80)}</p>
                </div>
                </div>
              </a> */}



              {/* v1 */}
              <div className="row creative-service">
                            <a  onClick={(item)=>{
                                      onClick(item)
                                    }}>
                                <div className='pl-4' >
                                   
                                        <div className="service service__style--2">
                                           <div className='prog-icon'>{items.icon}</div>
                                            <div className="content">
                                                <h3 className="prog-title">{items.name}</h3>
                                                <p className='desc'>{items.details.substring(0,480)}</p>
                                            </div>
                                        </div>    
                                   
                                </div>
                            </a>
                        </div>
						</motion.div>
             
             </>

					))}
          {
           progData? <Modal
             show={show}
             onHide={() => setShow(false)}
            //  dialogClassName="modal-95w"
             >
                 <Modal.Header closeButton>
                 <Modal.Title>{progData.name}</Modal.Title>
                 </Modal.Header>
                 <Modal.Body>
                     {/* <img src={progData.imageName}/> */}
                     <p>
                       {progData.details}
                     </p>
                    
                 </Modal.Body>
             </Modal> : ""
             
          }
         
				</div> 
        </AnimatePresence>
			{/* </SRLWrapper> */}

    </section>
	
    </>
	);
}

const TagButton = ({ name, handleSetTag, tagActive }) => {
	return (
		<button className={`tag ${tagActive ? 'active' : null}`} onClick={() => handleSetTag(name)}>
			
      {name.toUpperCase()}
      
		</button>
	);
}

export default WelcomeNote
