import React,{useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './card.css'
import { Button } from './Button'
// import './home.css'
const ProgramCard = (props) => {
  // useEffect(() => {
  //   axios
  //     .get(api.frontBlogs)
  //     .then((res) => {
  //       setFrontBlog(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  return <>
 
          <div className='col-md-4 '>
            <div className=' shadow'>
            <div className="h-100 card shadow anim">
              <Link to={props.id}>
               <img src={props.imgsrc} className="card-img-top inner" alt="..."/>
              </Link>
                 
             
             
              <div className="card-body">
                <Link to={props.id}>
                <h5 className="card-title"><strong>{props.title}</strong></h5>
                </Link>
                  
                  {/* <p className=" card-text text-justify" dangerouslySetInnerHTML={{__html:props.details.substring(0,80)}} ></p>
                  <Button to={props.id} className="mt-2 home-button-program">Read more</Button> */}
                </div> 
                
               
            </div> 
            </div>
          </div>
       
  </>
}

export default ProgramCard