import React, { useEffect } from "react";
import { Button } from "./Button";
import "./home.css";
import Aos from "aos";
import "aos/dist/aos.css";
import './pricesection.css'
import { NavLink } from "react-router-dom";
import { SiPandas } from "react-icons/si";

// import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';

const PriceSection = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
    <div data-aos="fade-up"   class="demo">
        <div className="col-12 text-center mb-2 pt-2">
          <h2 className="hero-title contact-title text-uppercase "> <strong>PACKAGES</strong> WE OFFER </h2>
        </div>
    {/* <div class=" price-container"> */}
        
        <div class="row justify-content-center mt-5 ">
            <div class="col-md-5 col-sm-12 mb-5">
                <div  class="pricingTable">
                    <div class="pricingTable-header">
                    <div>
                        <span ><del class="org-price">$845</del></span>
                    </div>
                        <div class="price-value"> $397.00 <span class="month"> LIMITED TIME OFFER
</span> </div>
                    </div>
                    <h3 class="heading">TRANSFORMATION 30   
AVAILABLE FOR ONLY
</h3>
                    <div class="pricing-content">
                        <ul>
                        <li className="priceList-bga">Hypnotic relaxation recording to introduce you to hypnotherapy sessions Value $99
</li>
                        <li className="priceList-bgb">1.5 hour to 2 hour session to address Issue/concern Value $350
</li>
                        <li className="priceList-bga">Hypnotic custom voice recording Value $198
</li>
                        <li className="priceList-bgb">15-minute check-in call 7 days after the session Value $99 
</li>
                        <li className="priceList-bga">Unlimited email support for 30 days after the session Value is Priceless
</li>
                        <li>
                            <strong>TOTAL VALUE $845 </strong>
                        </li>
                        </ul>
                    </div>
                    <button class="pricingTable-signup">
                       <a href="/appointment">Book Now</a>
                      
                    </button>
                </div>
            </div>
            <div class="col-md-1 col-sm-12"></div>
            <div class="col-md-5 col-sm-12 mb-5">
                <div class="pricingTable ">
                    <div class="pricingTable-header">
                    <div>
                        <span>  <del class="org-price">$1787</del></span>
                    </div>
                        <div class="price-value"> $797.00 <span  class="month"> LIMITED TIME OFFER
</span> </div>
                    </div>
                    <h3 class="heading">TRANSFORMATION 60 + THERAPEUTIC EFT
</h3>
                    <div class="pricing-content">
                        <ul>
                        <li className="priceList-bga">Hypnotic relaxation recording Value $99
</li>
                        <li> (2) 1.5 hour to 2 hour hypnotherapy session to address main issue/concern Value $700
</li>
                        <li className="priceList-bga"> (2) Custom recording Transformation Value $396
</li>
                        <li> (2) 15 minute check-in call 7 days after the session Value $198</li>
                        <li className="priceList-bga"> (2) 15 minute check-in call 30 days after the session Value $198</li>
                        <li> (1) 1 hour Therapeutic EFT session to help improve you forward Value $196</li>
                        <li className="priceList-bga">Unlimited email support for 60 days after first session Value is Priceless</li>
                        <li>
                            <strong>TOTAL VALUE $1787</strong>
                        </li>
                        </ul>
                    </div>
                    <div class="pricingTable-signup">
                        <a href="/appointment">Book Now</a>
                    </div>
                </div>
            </div>
          
            <div class="col-md-5 col-sm-12 mb-4 ">
                <div class="pricingTable ">
                    <div class="pricingTable-header">
                    <div>
                        <span ><del class="org-price">$2628</del> </span>
                    </div>
                        <div class="price-value"> $1841.00 <span class="month">LIMITED TIME OFFER
</span> </div>
                    </div>
                    <h3 class="heading">TRANSFORMATION 90 + THERAPEUTIC EFT
</h3>
                    <div class="pricing-content">
                        <ul>
                            <li className="priceList-bga">Hypnotic relaxation recording value $99
</li>
                            <li>(3) 1.5 hour to 2 hour hypnotherapy session to address main concern Value $1050
</li>
                            <li className="priceList-bga">(3) Custom recording Transformation Value $594
</li>
                            <li >(3) 15 minute check-in call 7 days after the session Value $297
</li>
                            <li className="priceList-bga">(3) 15 minute check-in call 30 days after the session Value $297
</li>
                            <li>(3) 1 hour Therapeutic EFT session to help improve you forward Value $588
</li>
                            <li className="priceList-bga"> Unlimited email support for 90 days after first session Value is Priceless
</li>
                            <li>
                                <strong>TOTAL VALUE $2628</strong>
                            </li>
                        </ul>
                    </div>
                    <div class="pricingTable-signup">
                        <a href="/appointment">Book Now</a>
                    </div>
                </div>
            </div>
            <div class="col-md-1 col-sm-12"></div>
            <div class="col-md-5 col-sm-12">
                <div class="pricingTable ">
                    <div class="pricingTable-header">
                    <div>
                        <span ><del class="org-price">$398</del> </span>
                    </div>
                        <div class="price-value"> $250.00 <span class="month">LIMITED TIME OFFER</span> </div>
                    </div>
                    <h3 class="heading">HYPNOTIC VIDEO DEEPENER
</h3>
                    <div class="pricing-content">
                        <ul>
                       <li >
30 minute consultation Value $199 
</li>
                       <li className="priceList-bga">Hypnotic Video Deepener created with custom script and voice over with background music and animation to reinforce installation of new positive beliefs into the subconsci!ous mind Length 10-15 minutes Value $199
</li>

                        <li>
                            <strong>TOTAL VALUE $398</strong>
                        </li>
                        </ul>
                    </div>
                    <div class="pricingTable-signup">
                        <a href="/appointment">Book Now</a>
                    </div>
                </div>
         
        </div>
</div>
    {/* </div> */}
</div>
{/* <a target="_blank" href="#" className="white-mode">MORE ></a> */}
</>
  );
};

export default PriceSection;
