import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { IMG_BASE_URL} from './api/api'
import { Link } from "react-router-dom";
import axios from "axios";
import "./blogsection.css";


const BlogSection = () => {
  const { id } = useParams();

  const [blog, setBlog] = useState([]);

  useEffect(() => {
    axios.get(`https://www.admin.femnivision.com/api/blog/${id}`).then((res) => {
        setBlog(res.data);
    });
  }, []);
 
  return (
    <>
    <h2 className="pt-5 mb-5 text-center servicestext"> {blog.title}
    <p className="pt-5 blog-date">
    {`Published ${blog.created_at}`}
            </p>
    </h2>
      <div className="mt-5 myrow">
        <div className="mt-5 mycol">
          <div className="blog-wrap">
            <header>
              {/* <p className="pt-5 blog-date"> {`Published ${blog.created_at}`} </p> */}
              {/* <h2>{blog.title}</h2> */}
            </header>
            <img
              src={`${IMG_BASE_URL}/blogs/${blog.photo}`}
              alt="cover"
            />
            <p className="blog-desc"  dangerouslySetInnerHTML={{ __html: blog.description }}>
           
            </p>
          </div>
            
        </div>
      </div>
    </>
  );
};

export default BlogSection;
