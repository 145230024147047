import React from "react";
import image from "../src/image/diane.jpg";

import "./about.css";
function About(props) {
  return (
  <>
  <div className="title">
        <h1 className="about-title">ABOUT ME</h1>
      </div>
    <div className="container anim">
      
      <div className="contant row">
        <div className="imagecolum col-md-6 col-sm-12">
          <img className="shadow anim mb-3 float-left img-fluid" src={image} alt="" />
        
        </div>
        <div className="col-md-6 col-sm-12">
          <p className="text-justify">
            <strong>
              {" "}
              Certified RTT® Therapist | Certified Hypnotherapist | Certified Life Coach and  EFT Practitioner

            </strong>
            <br />
            <br />
            Diane Baxter is a CTI trained Life Coach with a Wainwright Global Institute certification in Professional Coaching for the last 10 years.  She has added to her list of skills a Marisa Peer certification as a Hypnotherapist, RTT® Therapy Practitioner and an ongoing interest in the art of EFT coaching.  Her vision is to bring women back to wholeness one woman at a time. 

            <br />
            <br />
            She grew up in a family full of women and witnessed firsthand the effects of low self-esteem and confidence.  Upon attending college, she first majored in Psychology like so many others but quickly realized that the way mental health was being handled in the 1970s was not the way she wanted to help others.  After changing her major to Business Administration, she had a long successful career in corporate America.  

            <br />
            <br/>
            After decades of feeling unfulfilled  her interest  in everything alternative, everything started to grow.  Finally while recovering from a serious illness she discovered “Mind Valley” and was introduced to Marisa Peer and the science of Rapid Transformational Therapy. She knew from the very first time she heard Marisa's RTT® technique, this is what she was looking for and now specializes in the areas of  Weight Management, Relationships and Self-Esteem/Confidence.  

            <br />
            <br/>
            We all have a calling, a reason why we are here, and a purpose for being.  She found her purpose in 2012 with her CTI life coach training but as always life happened and her new career was delayed.  Discovering Marisa Peer was not by accident or coincidence it was her destiny and the destiny of those she will free to become their best selves as they are freed of limiting beliefs by using her 3 Step Joy Acceleration Program.  

            <br />
            <br/>
           
          <br/>
          </p>
        </div>
      </div>
    </div>
    </>
  );
}

export default About;
