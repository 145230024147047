import React, { useEffect, useState } from "react";
import ProgramCard from "./ProgramCard";
import Sdata from "./Sdata";
import "./infosection.css";
import axios from "axios";
import { api, IMG_BASE_URL } from "./api/api";

function Program() {
  const [program, setProgram] = useState([]);

  useEffect(() => {
    axios
      .get(api.frontProgramm)
      .then((res) => {
        console.log(res.data);
        setProgram(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <section className=""> 
      <div className="text-center   hero-title ">
        <h2 className=" solution-text-h">
               
             <strong>NEED A SOLUTION? </strong>  
                
                
              </h2>
              <small className="small-tag" style={{fontSize:"40px"}}>Explore My Programs</small>
      </div>
      
        <div className="container pt-4">
          <div className="row justify-content-center">
            <div className="col-12">
             
            
            </div>
          </div>

          <div className="row mx-auto">
            {program.map((val, ind) => {
              return (
                <ProgramCard
                  key={ind}
                  imgsrc={`${IMG_BASE_URL}/services/${val.photo}`}
                  title={val.title}
                  details={val.sub_title}
                  id={`/program/${val.id}`}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default Program;
